import { Stack } from '@mui/material';
import Logo from 'components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader: React.FC = (): JSX.Element => {
    return (
        <Stack spacing={1} pt={1} alignItems="center">
            <Logo />
        </Stack>
    );
};

export default DrawerHeader;
