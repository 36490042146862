import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout: React.FC = (): JSX.Element => (
    <div className="login">
        <Outlet />
    </div>
);

export default MinimalLayout;
