// types
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import http from 'apis/http';
import { User } from 'apis/types';
import axios, { AxiosResponse } from 'axios';
import config from 'config';

export type State = Partial<Pick<User, 'id' | 'accessToken'>> & {
    loginError?: SerializedError;
};

export interface SignupState {
    user: User;
    password: string;
}

export interface LoginState {
    email: string;
    password: string;
}

// initial state
const initialState: State = {
    id: sessionStorage.getItem('id') ? parseInt(sessionStorage.getItem('id') as string) : undefined,
    accessToken: sessionStorage.getItem('accessToken') || undefined
};

// ==============================|| SLICE - AUTH ||============================== //

export const signup = createAsyncThunk<AxiosResponse, SignupState>('auth/signup', async ({ user, password }, { rejectWithValue }) => {
    const url = `${config.serverUrl}/auth/signup`;
    console.log('AUTH - SIGNUP URL', url);

    const params = new URLSearchParams();
    params.append('user', JSON.stringify(user));
    params.append('password', password);
    console.log('AUTH - SIGNUP PARAMS', params);

    try {
        const response = await http.post(url, params, {
            validateStatus: (status: number) => status >= 200 && status < 300
        });
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) return rejectWithValue(error.response?.data ?? error);
        throw error;
    }
});

export const login = createAsyncThunk<User, LoginState>('auth/login', async ({ email, password }, { rejectWithValue }) => {
    const url = `${config.serverUrl}/api/auth/signin`;
    console.log('AUTH - LOGIN URL', url);

    try {
        const { data } = await http.post<User>(
            url,
            {
                email,
                password
            },
            {
                validateStatus: (status: number) => status >= 200 && status < 300
            }
        );
        console.log('AUTH - LOGIN SUCCEDED', data);
        return data;
    } catch (error) {
        console.log('AUTH - LOGIN FAILED', error);
        if (axios.isAxiosError(error)) return rejectWithValue(error.response?.data ?? error);
        throw error;
    }
});

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            delete state.id;
            delete state.accessToken;
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('accessToken');
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signup.fulfilled, (state, action) => {
            console.log(`AUTH - SIGNED UP AS ${action?.meta?.arg?.user?.email || ''}`);
        });
        builder.addCase(signup.rejected, (state, action) => {
            console.error('AUTH - SIGN UP ERROR:', action.error?.message || '');
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const loggedUser = action.payload;
            state.loginError = undefined;
            state.id = loggedUser.id;
            state.accessToken = loggedUser.accessToken;
            sessionStorage.setItem('id', state.id ? String(state.id) : '');
            sessionStorage.setItem('accessToken', state.accessToken || '');
            console.log(`AUTH - LOGGED AS ${action?.payload?.email || ''}`);
        });
        builder.addCase(login.rejected, (state, action) => {
            delete state.accessToken;
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('accessToken');
            console.error('AUTH - LOGIN ERROR:', action || '');
            action.error = action.payload as SerializedError;
            state.loginError = action.error;
        });
    }
});

export type AuthStateType = ReturnType<typeof auth.reducer>;

export default auth.reducer;

export const { logout } = auth.actions;
