// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// initial state
export interface State {
    openItem?: Array<string>;
    openComponent?: string;
    drawerOpen?: boolean;
    componentDrawerOpen?: boolean;
}

const initialState: State = {
    openItem: ['profile'],
    openComponent: 'buttons',
    drawerOpen: false,
    componentDrawerOpen: true
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action: PayloadAction<State>) {
            state.openItem = action.payload.openItem;
        },

        activeComponent(state, action: PayloadAction<State>) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action: PayloadAction<State>) {
            state.drawerOpen = action.payload.drawerOpen;
        },

        openComponentDrawer(state, action: PayloadAction<State>) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        }
    }
});

export type MenuStateType = ReturnType<typeof menu.reducer>;

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer } = menu.actions;
