import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Route } from 'apis/types';
import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// project import
import { StateType } from 'store/reducers';
import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

export interface NavItemProps {
    route: Route;
    level?: number;
}

export interface ListItemProps {
    component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: string;
}

const NavItem: React.FC<NavItemProps> = ({ route, level = 0 }) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    const menu = useSelector((state: StateType) => state.menu);
    const { drawerOpen, openItem } = menu;

    const itemTarget = route.target ? '_blank' : '_self';

    const listItemProps = route.external
        ? {
              component: 'a',
              href: route.path,
              target: itemTarget
          }
        : {
              component: forwardRef<HTMLAnchorElement, unknown>((props, ref) => (
                  <Link ref={ref} {...Object.assign({}, props)} to={route.path || ''} target={itemTarget} />
              ))
          };

    const itemHandler = (id: string) => {
        dispatch(activeItem({ openItem: [id] }));
    };

    const Icon = route.icon;
    const itemIcon = Icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem && openItem.findIndex((id) => id === route.id) > -1;
    const key = route.id || route.title || '';

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === key);
        if (currentIndex > -1) {
            dispatch(activeItem({ openItem: [key] }));
        }
        // eslint-disable-next-line
    }, []);

    const textColor = 'text.primary';
    const iconSelectedColor = 'primary.main';

    return (
        <ListItemButton
            {...listItemProps}
            key={key}
            disabled={route.disabled}
            onClick={() => itemHandler(route.id || '')}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${(level + 1) * 28}px` : 1.5,
                py: !drawerOpen && level + 1 === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter'
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter'
                        }
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter'
                            }
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter'
                                }
                            })
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                            {route.title}
                        </Typography>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && route.chip && (
                <Chip
                    color={route.chip.color}
                    variant={route.chip.variant}
                    size={route.chip.size}
                    label={route.chip.label}
                    avatar={route.chip.avatar && <Avatar>{route.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
