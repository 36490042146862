import { Box, Toolbar, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { appRoutes } from 'routes';
import { StateType } from 'store/reducers';
// types
import { openDrawer } from 'store/reducers/menu';
// project import
import Drawer from './Drawer';
import { reduceForNavigation } from './Drawer/DrawerContent/Navigation';
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout: React.FC = (): JSX.Element => {
    const theme = useTheme();

    const [t] = useTranslation();

    const { accessToken } = useSelector((state: StateType) => state.auth);

    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state: StateType) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }, [dispatch, matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen, open]);

    /*const [routes, setRoutes] = useState<Route[]>([]);

    useEffect(() => {
        if (accessToken) {
            void (async () => {
                const newRoutes = reduceForNavigation(appRoutes(t), undefined, accessToken);
                setRoutes(newRoutes);
            })();
        }
    }, [accessToken, t]);*/

    const routes = useMemo(() => reduceForNavigation(appRoutes(t), undefined, accessToken), [accessToken, t]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar sx={{ opacity: 0 }} />
                <Breadcrumbs navigation={routes} title titleBottom card={false} divider={false} />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
