import { Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - BADGE ||============================== //

const Toolbar = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                    '& .MuiButtonBase-root': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main
                        }
                    }
                }
            }
        }
    };
};

export default Toolbar;
