import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import it from './it.json';

console.log('LANGUAGE', navigator.language);

const resources = {
    en: {
        translation: en
    },
    it: {
        translation: it
    }
};

void i18n.use(initReactI18next).init({
    resources,
    lng: navigator.language,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
