import config from 'config';
import http from './http';
import { ServerResponse, User, UserPassword } from './types';

export const get = async (accessToken: string): Promise<User | undefined> => {
    const url = encodeURI(`${config.serverUrl}/user`);
    console.log('USER - GET - URL', url);
    const response: ServerResponse<User> = await http.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (!response.data.code) throw response.data;
    return response.data.object;
};

export const update = async (accessToken: string, user: Partial<User & UserPassword>): Promise<User | undefined> => {
    const url = encodeURI(`${config.serverUrl}/user/update`);
    const { id, email, accessToken: removedAccessToken, ...userData } = user;
    console.log('USER - UPDATE - URL', url);
    console.log('USER - UPDATE - DATA', userData);
    const response: ServerResponse<User> = await http.post(url, userData, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });
    if (!response.data.code) throw response.data;
    return response.data.object;
};
