// project import
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ScrollTop from 'components/ScrollTop';
import { useTranslation } from 'react-i18next';
import Routes from 'routes';
import ThemeCustomization from 'themes';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App: React.FC = (): JSX.Element => {
    const { i18n } = useTranslation();
    return (
        <ThemeCustomization>
            <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
                <ScrollTop>
                    <Routes />
                </ScrollTop>
            </LocalizationProvider>
        </ThemeCustomization>
    );
};

export default App;
