import { Box } from '@mui/material';
import { Route } from 'apis/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { appRoutes } from 'routes';
import { StateType } from 'store/reducers';
import NavGroup from './NavGroup';
import NavItem from './NavItem';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

export const reduceForNavigation = (routes: Route[], path?: string, accessToken?: string) => {
    return routes.reduce((accumulator, element) => {
        const route = Object.assign({}, element) as Route;
        if ((accessToken && route.requireAuth !== false) || (!accessToken && !route.requireAuth)) {
            if (path && !route.external) {
                route.path = `${path}${route.path || ''}`;
            }
            if (route.children?.length) {
                route.children = reduceForNavigation(route.children, route.path, accessToken);
            }
            if (route.title) {
                if ((route.path && route.element) || route.children?.length) {
                    accumulator.push(route);
                }
            } else if (route.children?.length) {
                accumulator.push(...route.children);
            }
        }
        return accumulator;
    }, [] as Route[]);
};

const Navigation: React.FC = () => {
    const [t] = useTranslation();

    const { accessToken } = useSelector((state: StateType) => state.auth);
    /*const [routes, setRoutes] = useState<Route[]>([]);

    useEffect(() => {
        if (accessToken) {
            void (async () => {
                const newRoutes = reduceForNavigation(appRoutes(t), undefined, accessToken);
                setRoutes(newRoutes);
            })();
        }
    }, [accessToken, t]);*/

    const routes = reduceForNavigation(appRoutes(t), undefined, accessToken);

    const navGroups = useMemo(() => {
        return routes.map((route) =>
            route.title && !route.element ? (
                <NavGroup key={route.title} group={route} />
            ) : (
                <NavItem key={route.id || route.title} route={route} />
            )
        );
    }, [routes]);

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
